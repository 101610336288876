input,
textarea {
    background: transparent;

    /* Placeholder color */
    &::placeholder {
        @apply text-hint;
    }

    &::placeholder {
        @apply text-hint;
    }

    &::input-placeholder {
        @apply text-hint;
    }

    &:input-placeholder {
        @apply text-hint;
    }

    &:-webkit-autofill {
        transition: 'background-color 9999s ease-out';
        transition-delay: 9999s;
    }

    &:-webkit-autofill:hover {
        transition: 'background-color 9999s ease-out';
        transition-delay: 9999s;
    }

    &:-webkit-autofill:focus {
        transition: 'background-color 9999s ease-out';
        transition-delay: 9999s;
    }

    &:-webkit-autofill:active {
        transition: 'background-color 9999s ease-out';
        transition-delay: 9999s;
    }
}
