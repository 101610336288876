@mixin override-mat-tab-link {
  .booking-list {
    .mat-tab-header {
      border-bottom: none !important;

      .mat-tab-labels {
        width: max-content;
        @apply justify-between gap-8
      }

      .mat-tab-label {
        font-size: 18px;
        font-weight: 600;
        padding: 0 !important;
        min-width: 0 !important;

        &.mat-tab-label-active {
          opacity: 100%;
          color: #b637cc;
        }
      }
    }
  }
}

@mixin Override-mat-datepicker() {
  .month-date-picker {
    .mat-calendar-period-button {
      display: none !important;
    }
  }
}

@mixin override-phone-number-field() {
  .custom-phone-number {
    position: relative;
    display: flex;
    align-items: stretch;
    min-height: 48px;
    border-radius: 6px;
    padding: 0 16px;
    border-width: 1px;
    --tw-border-opacity: 1 !important;
    border-color: rgba(203, 213, 225, var(--tw-border-opacity)) !important;
  }

  .custom-phone-number {
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 14px;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 14px;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: 14px;
    }
  }
}


@mixin table-override() {
  table.custom-table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5em;
  }
  table tr:last-child td /*To remove the last border*/
  {
    border-bottom: 0 solid
  }
}

@mixin override-mat-checkbox {
  .custom-checkbox {
    .mat-checkbox-inner-container {
      width: 22px !important;
      height: 22px !important;
    }

    .mat-checkbox-frame {
      border: #7a7a7a solid 3px !important;
      border-radius: 4px !important;
    }
  }

  mat-checkbox.custom-checkbox .mat-checkbox-checkmark-path {
    stroke: white !important;
    stroke-width: 2px !important;
  }
}
