@use '@angular/material' as mat;

@include mat.core(
  mat.define-typography-config(
    $font-family: theme('fontFamily.sans'),
    $title: mat.define-typography-level(1.25rem, 2rem, 600),
    $body-2: mat.define-typography-level(0.875rem, 1.5rem, 600),
    $button: mat.define-typography-level(0.875rem, 0.875rem, 500),
    $input: mat.define-typography-level(0.875rem, 1.2857, 400)
  )
);

@mixin application-theme($theme) {
  @include mat.all-component-themes($theme);
}

body.light {
  $primary: mat.define-palette(mat.$purple-palette);
  $accent: mat.define-palette(mat.$teal-palette);
  $theme: mat.define-light-theme($primary, $accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include application-theme($theme);

  color: map-get($foreground, text);
  background-color: #f1f5f9;
}

body.dark {
  $primary: mat.define-palette(mat.$green-palette);
  $accent: mat.define-palette(mat.$teal-palette);
  $theme: mat.define-dark-theme($primary, $accent);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  @include application-theme($theme);

  color: map-get($foreground, text);
  background-color: map-get($background, background);
}
