/* You can add global styles to this file, and also import other style files */

@import 'apps/admin/src/app/app.theme';
@import "material-override";

:root {
  --fc-small-font-size: 1rem;
}

.custom-border-color-green {
  .mat-form-field.mat-form-field-appearance-fill.mat-focused:not(.mat-form-field-invalid)
  .mat-form-field-wrapper
  .mat-form-field-flex {
    @apply border-accent-500  #{!important};
  }
}


.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #e3e3e3 !important;
}

.custom-chip-list {
  .mat-chip-list-wrapper {
    margin: 1rem 0 !important;
  }

  .mat-chip {
    background-color: #f3f3f3 !important;
    padding: 7px 28px;
    color: #8d8d8d !important;
    opacity: 1 !important;
  }
}

mat-drawer-container.mat-drawer-container {
  position: unset !important;
  z-index: 15 !important;

  &.below-nav {
    z-index: 3 !important;
  }
}

.grid, .border, .border-b, .border-t {
  border-color: #e2e8f0 !important;
}

//material override
@include override-mat-tab-link();
@include Override-mat-datepicker();
@include override-phone-number-field();
@include table-override();
@include override-mat-checkbox();
