/* 1. Components */
@import 'components/input';

/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* 2. Overrides */
@import 'overrides/angular-material';
@import 'overrides/perfect-scrollbar';

// Tailwind
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {
  * {
    /* Text rendering */
    text-rendering: optimizelegibility;
    -o-text-rendering: optimizelegibility;
    -ms-text-rendering: optimizelegibility;
    -moz-text-rendering: optimizelegibility;
    -webkit-text-rendering: optimizelegibility;
    -webkit-tap-highlight-color: transparent;

    /* Remove the focus ring */
    &:focus {
      outline: none !important;
    }
  }

  /* HTML and Body default styles */
  html,
  body {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-height: 100%;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  /* Font size */
  html {
    font-size: 16px;
  }

  body {
    font-size: 0.875rem;
    /* Stylistic alternates for Inter */
    font-feature-settings: 'salt';

    height: 100%;
  }

  /* Better spacing and border for horizontal rule */
  hr {
    margin: 32px 0;
    border-bottom-width: 1px;
  }

  /* Make images and videos to take up all the available space */
  img {
    width: 100%;
    vertical-align: top;
  }

  /* Fix: Disabled placeholder color is too faded on Safari */
  input[disabled] {
    opacity: 1;
    -webkit-text-fill-color: currentcolor;
  }

  body,
  .dark,
  .light {
    @apply text-default bg-default #{'!important'};
  }

  *,
  *::before,
  *::after {
    --tw-border-opacity: 1 !important;

    border-color: rgb(var(--fuse-border-rgb) var(--tw-border-opacity));

    .dark & {
      --tw-border-opacity: 0.12 !important;
    }
  }

  [disabled] * {
    @apply text-disabled #{'!important'};
  }

  /* Print styles */
  @media print {
    /* Make the base font size smaller for print so everything is scaled nicely */
    html {
      font-size: 12px !important;
    }

    body,
    .dark,
    .light {
      background: none !important;
    }
  }
}

.fields-wrapper {
  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  mat-form-field {
    width: 100%;

    label {
      top: -0.25rem !important;
      @apply text-sm #{!important};
    }
  }
}

.no-padding-dialog .mat-dialog-container {
  padding: 0;
}

.mat-drawer-container {
  z-index: 15 !important;

  &.below-nav {
    z-index: 3 !important;
  }
}
